import { createSelector } from "@reduxjs/toolkit"

import { capitalCase } from '../../app/utils';
import {
  selectTotalStrandingsByAge,
  selectTotalStrandingsByCause,
  selectTotalStrandingsByCondition,
  selectTotalStrandingsBySex
} from '../stranding-data/strandingDataSelectors';
import { selectCounty, selectStrandingFeature } from './detailsByCountySlice';

export const selectDonutData = createSelector(
  [
    selectCounty,
    selectStrandingFeature,
    selectTotalStrandingsByAge,
    selectTotalStrandingsByCause,
    selectTotalStrandingsByCondition,
    selectTotalStrandingsBySex
  ],
  (
    county,
    strandingFeature,
    strandingsByAge,
    strandingsByCause,
    strandingsByCondition,
    strandingsBySex
  ) => {
    let selectedFeature;
    switch (strandingFeature) {
      case 'AGE':
        selectedFeature = strandingsByAge;
        break;
      case 'CAUSE':
        selectedFeature = strandingsByCause;
        break;
      case 'SEX':
        selectedFeature = strandingsBySex;
        break;
      case 'CONDITION':
      default:
        selectedFeature = strandingsByCondition;
    }

    return Object.entries(selectedFeature[county]).map(
      ([key, val]) => ({ label: capitalCase(key), value: val as number })
    );
  }
)