import React from 'react';
import { Box, Heading, Paragraph } from 'theme-ui';
import { useSelector } from 'react-redux';

import { selectStrandingFeature } from './detailsByCountySlice';

const DetailsByCountyIntroContent = () => {
  const selectedFeature = useSelector(selectStrandingFeature);
  switch (selectedFeature) {
    case 'CONDITION':
      return (
        <>
          <Heading as="h3" variant="styles.h6" sx={{ mb: 3 }}>
            Condition
          </Heading>
          <Paragraph>
            Otter condition is assessed at recovery.
          </Paragraph>
        </>
      )
    case 'CAUSE':
      return (
        <>
          <Heading as="h3" variant="styles.h6" sx={{ mb: 3 }}>
            Cause of Stranding (COS)
          </Heading>
          <Paragraph>
            The cause of stranding (COS) for each sea otter is identified through necropsy (animal autopsy), but these findings can change as investigations continue. The data only denotes the main cause, even though many strandings have multiple contributing factors. Some causes might be under-represented because they need further testing and microscopic examination, which may be still ongoing.
          </Paragraph>
        </>
      )
    case 'AGE':
    case 'SEX':
      return (
        <>
          <Heading as="h3" variant="styles.h6" sx={{ mb: 3 }}>
            Sex and Age Class
          </Heading>
          <Paragraph>
            For each stranded sea otter, scientists try to identify its age and sex. They determine sex by looking at genitalia or, if necessary, examining internal organs and bone structure. Age is figured out by looking at the otter's teeth, size, fur, and skull features.
          </Paragraph>
        </>
      )
  }
}

const DetailsByCountyIntro = () => (
  <Box sx={{ mb: 4, p: { fontSize: `12px`} }}>
    <DetailsByCountyIntroContent />
  </Box>
)

export default DetailsByCountyIntro