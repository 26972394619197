import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StrandingByRegionState } from './strandingByRegionTypes';


const initialState: StrandingByRegionState = {
  mapYear: `ALL`,
  strandingsOverTimeYear: `ALL`,
  totalStrandingsYear: `ALL`,
};

export const strandingByRegionSlice = createSlice({
  name: 'strandingByRegion',
  initialState,
  reducers: {
    chooseMapYear: (state, action) => {
      state.mapYear = action.payload
    },
    chooseStrandingsOverTimeYear: (state, action) => {
      state.strandingsOverTimeYear = action.payload
    },
    chooseTotalStrandingsYear: (state, action) => {
      state.totalStrandingsYear = action.payload
    },
  },
});

export const selectMapYear = (state: RootState) => state.strandingByRegion.mapYear;
export const selectStrandingsOverTimeYear =
  (state: RootState) => state.strandingByRegion.strandingsOverTimeYear;
export const selectTotalStrandingsYear =
  (state: RootState) => state.strandingByRegion.totalStrandingsYear;

export const {
  chooseMapYear,
  chooseStrandingsOverTimeYear,
  chooseTotalStrandingsYear,
} = strandingByRegionSlice.actions;

export default strandingByRegionSlice.reducer;
