import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { DetailsByCountyState } from './detailsByCountyTypes';


const initialState: DetailsByCountyState = {
  county: `ALL`,
  strandingFeature: 'CONDITION',
  detailYear: `ALL`,
};

export const detailsByCountySlice = createSlice({
  name: 'detailsByCounty',
  initialState,
  reducers: {
    chooseCounty: (state, action) => {
      state.county = action.payload
    },
    chooseStrandingFeature: (state, action) => {
      state.strandingFeature = action.payload
    },
    chooseDetailYear: (state, action) => {
      state.detailYear = action.payload
    },
  },
});

export const selectCounty = (state: RootState) => state.detailsByCounty.county;
export const selectStrandingFeature =
  (state: RootState) => state.detailsByCounty.strandingFeature;
export const selectDetailYear =
  (state: RootState) => state.detailsByCounty.detailYear;

export const {
  chooseCounty,
  chooseStrandingFeature,
  chooseDetailYear,
} = detailsByCountySlice.actions;

export default detailsByCountySlice.reducer;
