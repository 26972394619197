import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import strandingDataReducer from '../features/stranding-data/strandingDataSlice';
import strandingByRegionReducer from
  '../features/stranding-by-region/strandingByRegionSlice'
import californiaTopoDataReducer from
  '../features/california-topo-data/californiaTopoDataSlice';
import detailsByCountyReducer from
  '../features/details-by-county/detailsByCountySlice';

export const store = configureStore({
  reducer: {
    strandingData: strandingDataReducer,
    strandingByRegion: strandingByRegionReducer,
    californiaTopoData: californiaTopoDataReducer,
    detailsByCounty: detailsByCountyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
