import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchCaliforniaTopoData } from './californiaTopoDataAPI';
import { CaliforniaTopoDataState } from './californiaTopoDataTypes';

const initialState: CaliforniaTopoDataState = {
  value: undefined,
  status: 'idle',
};

export const fetchDataAsync = createAsyncThunk(
  'californiaTopoData/fetchData',
  async () => {
    const response = await fetchCaliforniaTopoData();
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const californiaTopoDataSlice = createSlice({
  name: 'californiaTopoData',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchDataAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectData = (state: RootState) => state.californiaTopoData.value;

export default californiaTopoDataSlice.reducer;
