import { Dictionary, createSelector } from "@reduxjs/toolkit"
import groupBy from "lodash-es/groupBy"
import { selectData as selectAllData } from "./strandingDataSlice"
import { StrandingDataValue, StrandingDatum } from "./strandingDataTypes"
import {
  selectMapYear,
  selectStrandingsOverTimeYear,
  selectTotalStrandingsYear
} from "../stranding-by-region/strandingByRegionSlice"
import { selectDetailYear } from "../details-by-county/detailsByCountySlice"

const aggregateTypeByCounties =
  (strandingByType: Dictionary<StrandingDatum[]>, counties:Array<string>) => {
    const output: {[key: string]: any} = {}

    output['ALL'] = Object.fromEntries(
      Object.entries(strandingByType).map(
        ([key, val]) => [key, (Array.isArray(val) && val.length) || 0]
      ),
    )

    counties.forEach((county) => {
      output[county] = Object.fromEntries(
        Object.entries(strandingByType).map(
          ([key, val]) => [
            key,
            (Array.isArray(val) && val.filter(
              (item) => item['COUNTY'] === county
            ).length) || 0
          ]
        ),
      )
    })

    return output
}

const filterAllDataByYear = (
  allData: StrandingDataValue,
  year: string
) => {
  if (year === 'ALL') {
    return allData.data
  }

  return allData.data?.filter(
    (datum: StrandingDatum) => datum.DATE.includes(year)
  )
}

export const selectAllDataByYear = createSelector(
  [selectAllData, selectMapYear],
  (allData: StrandingDataValue, mapYear: string) => {
    return filterAllDataByYear(allData, mapYear)
  }
)

export const selectAllDataGroupedByDate = createSelector(
  [selectAllData],
  (allData: StrandingDataValue) => (groupBy(allData.data, 'DATE'))
)

export const selectAllDataGroupedByMonth = createSelector(
  [selectAllData],
  (allData: StrandingDataValue) => (
    groupBy(allData.data, (val: StrandingDatum) => (val.DATE.substring(0,7)))
  )
)

export const selectCounties = createSelector(
  [selectAllData],
  (allData: StrandingDataValue = { data: []}) => {
    return Array.from(
      new Set(
        allData.data && allData.data.map(
          (item: StrandingDatum) => item['COUNTY']
        )
      )
    )
  }
)

const getStrandingsByCounty = (
  inputData: [string, StrandingDatum[] | undefined][],
  counties: string[]
) => {
  // Initialize the stranding count for each county to zero for each date
  const initialState: {[key: string]: any} = {}
  counties.forEach((county) => initialState[county] = 0)

  return inputData.map(([date, strandings]) => {
    const summaryForDate = strandings?.reduce(
      (summary: {[key: string]: any}, current) => {
        if (summary[current['COUNTY']] !== undefined) {
          summary[current['COUNTY']] += 1
        }
        return summary
      }, {...initialState})
    return {
      'DATE': date,
      ...summaryForDate
    }
  })
}

const getStrandingsByCountyFilteredByYear = (
  allDataByMonth: Dictionary<StrandingDatum[]>,
  counties: string[],
  year: string
) => {
  const filteredData = year === 'ALL' || year === undefined ?
    Object.entries(allDataByMonth) :
    Object.entries(allDataByMonth).filter(([date]) => (
      date.includes(year)
    ))

  return getStrandingsByCounty(filteredData, counties)
}


export const selectAllStrandingsByCounty = createSelector(
  [selectAllDataGroupedByMonth, selectCounties],
  (allDataByMonth, counties) => {
    return getStrandingsByCounty(Object.entries(allDataByMonth), counties)
  }
)

export const selectAllStrandingsByCountyFilteredByYear = createSelector(
  [
    selectAllDataGroupedByMonth,
    selectCounties,
    selectStrandingsOverTimeYear
  ],
  (allDataByMonth, counties, year) => {
    return getStrandingsByCountyFilteredByYear(allDataByMonth, counties, year)
  }
)

export const selectTotalStrandingsByCounty = createSelector(
  [selectAllDataGroupedByMonth, selectCounties, selectTotalStrandingsYear],
  (allDataByMonth, counties, year) => {
    // Initialize the stranding totals for each county to zero
    const initialState: {[key: string]: any} = {}
    counties.forEach((county) => initialState[county] = 0)

    const filteredStrandings =
      getStrandingsByCountyFilteredByYear(allDataByMonth, counties, year)

    return filteredStrandings.reduce(
      (totalsByCounty: {[key: string]: any}, current) => {
        Object.entries(current).forEach(([currentKey, value]) => {
          if (totalsByCounty[currentKey] !== undefined) {
            totalsByCounty[currentKey] += value
          }
        })
        return totalsByCounty
      }, {...initialState})
  }
)

export const selectAllDataGroupedByCondition = createSelector(
  [selectAllData, selectDetailYear],
  (allData: StrandingDataValue, year) => (
    groupBy(filterAllDataByYear(allData, year), 'CONDITION')
  )
)

export const selectConditions = createSelector(
  [selectAllData],
  (allData: StrandingDataValue = { data: []}) => {
    return Array.from(
      new Set(
        allData.data && allData.data.map(
          (item: StrandingDatum) => item['CONDITION']
        )
      )
    )
  }
)

export const selectTotalStrandingsByCondition = createSelector(
  [selectAllDataGroupedByCondition, selectCounties],
  (strandingsByCondition, counties) => {
    return aggregateTypeByCounties(strandingsByCondition, counties)
  }
)

export const selectAllDataGroupedBySex = createSelector(
  [selectAllData, selectDetailYear],
  (allData: StrandingDataValue, year) => {
    const expandKeyName = (key: string) => {
      switch (key) {
        case 'F':
          return 'FEMALE';
        case 'M':
          return 'MALE';
        case 'U':
        default:
          return 'UNKNOWN';
      }
    }

    return Object.fromEntries(
      Object.entries(groupBy(filterAllDataByYear(allData, year), 'SEX')).map(
        ([key, val]) => [expandKeyName(key), val]
      ),
    )
  }
)

export const selectSexes = createSelector(
  [selectAllData],
  (allData: StrandingDataValue = { data: []}) => {
    return Array.from(
      new Set(
        allData.data && allData.data.map(
          (item: StrandingDatum) => item['SEX']
        )
      )
    )
  }
)

export const selectTotalStrandingsBySex = createSelector(
  [selectAllDataGroupedBySex, selectCounties],
  (strandingsBySex, counties) => {
    return aggregateTypeByCounties(strandingsBySex, counties)
  }
)

export const selectAllDataGroupedByAge = createSelector(
  [selectAllData, selectDetailYear],
  (allData: StrandingDataValue, year) => (
    groupBy(filterAllDataByYear(allData, year), 'AGE CLASS')
  )
)

export const selectAges = createSelector(
  [selectAllData],
  (allData: StrandingDataValue = { data: []}) => {
    return Array.from(
      new Set(
        allData.data && allData.data.map(
          (item: StrandingDatum) => item['AGE CLASS']
        )
      )
    )
  }
)

export const selectTotalStrandingsByAge = createSelector(
  [selectAllDataGroupedByAge, selectCounties],
  (strandingsByAge, counties) => {
    return aggregateTypeByCounties(strandingsByAge, counties)
  }
)

export const selectAllDataGroupedByCause = createSelector(
  [selectAllData, selectDetailYear],
  (allData: StrandingDataValue, year) => (
    groupBy(filterAllDataByYear(allData, year), 'COS CATEGORY')
  )
)

export const selectCauses = createSelector(
  [selectAllData],
  (allData: StrandingDataValue = { data: []}) => {
    return Array.from(
      new Set(
        allData.data && allData.data.map(
          (item: StrandingDatum) => item['COS CATEGORY']
        )
      )
    )
  }
)

export const selectTotalStrandingsByCause = createSelector(
  [selectAllDataGroupedByCause, selectCounties],
  (strandingsByCause, counties) => {
    return aggregateTypeByCounties(strandingsByCause, counties)
  }
)
