import React from 'react';
import { Select, Box, Label } from 'theme-ui';
import { useSelector } from 'react-redux';

import { selectCounties } from '../stranding-data/strandingDataSelectors';
import { CountyNames } from '../stranding-data/strandingDataTypes';
import { capitalCase } from '../../app/utils';
import { useAppDispatch } from '../../app/hooks'
import { chooseCounty } from './detailsByCountySlice';

const CountySelectField = () => {
  const counties = useSelector(selectCounties) as CountyNames[];
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Label>County</Label>
      <Select onChange={(e) => dispatch(chooseCounty(e.target.value))}>
        <option value="ALL">All Counties</option>
        {counties.map((county) => (
          <option key={`${county.replace(/\s/g, '_')}`} value={county}>
            {capitalCase(county)}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default CountySelectField;
