import React from 'react';
import { Box } from 'theme-ui';
import { useSelector } from 'react-redux';

import { selectStrandingFeature } from './detailsByCountySlice';

const DetailsByCountyLegendContent = () => {
  const selectedFeature = useSelector(selectStrandingFeature);
  switch (selectedFeature) {
    case 'AGE':
      return (
        <table>
          <tbody>
            <tr>
              <td>Pup</td>
              <td>all or most teeth are deciduous, TL 40-90cm, natal pelage, no sagittal crest, all skull sutures open; age range 0-3 months.</td>
            </tr>
            <tr>
              <td>Immature</td>
              <td>some deciduous and some permanent teeth present, TL 80-105cm, all or nearly all natal pelage shed; age range 4-11 months.</td>
            </tr>
            <tr>
              <td>Subadult</td>
              <td>all deciduous teeth shed and little to no tooth wear evident, TL 95-115cm (females)/100-125cm (males), full adult pelage, Basioccipital-basisphenoid suture open but most other sutures closed; age range 1-3 years.</td>
            </tr>
            <tr>
              <td>Adult</td>
              <td>slight to obvious tooth wear, TL {'>'}105cm (females)/{'>'}115cm (males), pelt with some grizzle (typically), sutures closed, Lambdoidal and Sagittal crests developing; age range 4-9 years.</td>
            </tr>
            <tr>
              <td>Aged Adult</td>
              <td>severe tooth wear, TL same as adult category, pelt generally with extensive grizzle, Lambdoidal and Sagittal crests well developed; age range ≥10 years.</td>
            </tr>
            <tr>
              <td>Unknown</td>
              <td>age class could not be determined, generally due to missing skeletal components or because the carcass was not recovered.</td>
            </tr>
          </tbody>
        </table>
      )
    case 'CONDITION':
      return (
        <table>
          <tbody>
            <tr>
              <td>Alive</td>
              <td>moribund, injured, or abandoned.</td>
            </tr>
            <tr>
              <td>Fresh</td>
              <td>freshly dead; fur does not pull out easily, may or may not be in rigor.</td>
            </tr>
            <tr>
              <td>Moderate</td>
              <td>moderately decomposed; not in rigor, fur pulls out when tugged.</td>
            </tr>
            <tr>
              <td>Advanced</td>
              <td>advanced decomposition; fur sloughing or easily removed from skin, accumulation of gases in cavity and tissues (bloated), tissues liquefying, maggots likely present.</td>
            </tr>
            <tr>
              <td>Mumm/Skel</td>
              <td>mummified, fragmented, or skeletal remains; old dried carcass.</td>
            </tr>
            <tr>
              <td>Unknown</td>
              <td>condition of carcass unknown (generally because carcass was not recovered).</td>
            </tr>
          </tbody>
        </table>
      )
    case 'SEX':
      return (
        <table>
          <tbody>
            <tr>
              <td>Unknown</td>
            </tr>
            <tr>
              <td>Female</td>
            </tr>
            <tr>
              <td>Male</td>
            </tr>
          </tbody>
        </table>
      )
    case 'CAUSE':
      return (
        <table>
          <tbody>
            <tr>
              <td>Unknown</td>
              <td>primary COS and presence/absence of trauma could not be determined, usually due to severity of decomposition, scavenging, or because the carcass was not recovered. This category includes dead dependent pups observed with their mothers.</td>
            </tr>
            <tr>
              <td>Unknown, with trauma</td>
              <td>primary COS could not be determined, but some form of trauma was evident (this includes lacerations of unknown origin and dependent pups with trauma).</td>
            </tr>
            <tr>
              <td>Unknown, no trauma</td>
              <td>primary COS could not be determined, but no trauma was evident (and carcass condition was suitable to determine presence of trauma).
              </td>
            </tr>
            <tr>
              <td>Shark bite</td>
              <td>primary COS was suspected or confirmed shark bite (as indicated by multiple stab- like wounds, shark tooth fragments, or diagnostic white shark tooth scratch patterns on bones).</td>
            </tr>
            <tr>
              <td>Anthropogenic</td>
              <td>primary COS was confirmed or suspected to be directly human-related such as gunshot, fishing line entanglement, boat strike, net- or trap-drowned, research-related, or oil spill-related.</td>
            </tr>
            <tr>
              <td>Dependent animal</td>
              <td>primary COS for pups and smaller immature otters (TL{`<`}95cm) for which maternal separation is the most likely COS (no trauma or other apparent COS).</td>
            </tr>
            <tr>
              <td>Misc</td>
              <td>primary COS does not fall into other categories (shark bite, dependent animal); COS in this category include conditions such as acanthocephalan peritonitis, end lactation syndrome, mating trauma, intraspecific fight trauma, cardiomyopathy, domoic acid toxicosis, toxoplasmosis, sarcosystosis, coccidioidomycosis, gastric torsion, and natural seep oiling (source confirmed or suspected).</td>
            </tr>
          </tbody>
        </table>
      )
    default:
      return (
        <Box>
          Please select a feature
        </Box>
      )
  }
};

const DetailsByCountyLegend = () => (
  <Box sx={{
    fontSize: `12px`,
    td: {
      padding: `1`,
      verticalAlign: `top`,
    },
    'td:first-of-type': {
      fontWeight: `bold`,
      paddingRight: 4,
      paddingLeft: 0,
    },
  }}>
    <DetailsByCountyLegendContent />
  </Box>
)

export default DetailsByCountyLegend;
