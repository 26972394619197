import React from "react"
import { useSelector } from 'react-redux';
import { Box, Heading } from "theme-ui";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import Bar from "@visx/shape/lib/shapes/Bar";
import { Text } from '@visx/text';

import { capitalCase } from "../../app/utils";
import { getColorScale } from './chartStyles';
import { CountyNames } from '../stranding-data/strandingDataTypes'
import { selectTotalStrandingsByCounty, selectCounties } from "../stranding-data/strandingDataSelectors";
import YearSelectField from "../../app/YearSelectField";
import { chooseTotalStrandingsYear } from "./strandingByRegionSlice";
import { ParentSize } from "@visx/responsive";

export interface HorizontalBarProps {
  height?: number,
  width?: number,
  margin?: { top: number; right: number; bottom: number; left: number },
}

const defaultMargin = { top: 110, right: 30, bottom: 30, left: 50 };

export default function HorizontalBarChart({
  height = 500,
  width = 460,
  margin = defaultMargin,
}: HorizontalBarProps) {
  const strandingsByCountyMap = useSelector(selectTotalStrandingsByCounty)
  const counties = useSelector(selectCounties) as CountyNames[]

  interface HorizontalBarData {
    county: string,
    strandings: number,
  }
  const strandingsByCounty = Object.entries(strandingsByCountyMap)
    .map(([county, strandings]) => ({ county, strandings }))
    .sort((a, b) => b.strandings - a.strandings);

  // accessors
  const getCounty = (d: HorizontalBarData) => d.county;
  const getStrandings = (d: HorizontalBarData) => Number(d.strandings);

  const colorScale = getColorScale(counties)

  return (
    <Box sx={{
      position: 'relative',
      margin: '2em 0',
      minWidth: [`100%`, `100%`, `100%`, `460px`],
      border: `1px solid white`,
    }}>
      <Box sx={{
        position: [`static`, `absolute`],
        margin: [`20px`, 0],
        width: `360px`,
        top: `20px`,
        left: `50px`,
      }}>
        <Heading as="h2" variant="styles.h3">
          Total Strandings by County
        </Heading>
        <Heading as="h3" variant="styles.h6" sx={{ mt: 2 }}>
          Total for All Counties{' - '}
          {strandingsByCounty.reduce((total, val) => total + val.strandings, 0)}
        </Heading>
      </Box>
      <Box sx={{
        position: [`static`, `absolute`],
        mx: [`20px`, 0],
        my: [`20px 0`, 0],
        right: `30px`,
        top: `40px`,
        mt: 0,
        minWidth: `100px`,
        '> div': {
          '> label': {
            display: [`block`, `none`]
          }
        }
      }}>
        <YearSelectField action={chooseTotalStrandingsYear} />
      </Box>
      <ParentSize>
        {({ width }) => {
          const isPhone = width < 457;
          // bounds
          const yMax = isPhone ? 360 : height - margin.top - margin.bottom;
          const xMax = width - margin.left - margin.right - 260;

          const yScale = scaleBand<string>({
            range: [0, yMax],
            round: true,
            domain: strandingsByCounty.map(getCounty),
            padding: 0.2,
          });

          const xScale = scaleLinear<number>({
            range: [0, xMax],
            round: true,
            domain: [0, Math.max(...strandingsByCounty.map(getStrandings))],
          });

          return (<svg width={width} height={isPhone ? 400 : height}>
            <Group
              top={isPhone ? 20 : margin.top}
              left={isPhone ? 23 : margin.left}
              width={xMax}
              height={yMax}
            >
              {strandingsByCounty.map((d) => {
                const name = getCounty(d);
                const strandings = getStrandings(d);
                const barWidth = yScale.bandwidth();
                const barHeight = xScale(strandings);
                const barY = yScale(name);
                return (
                  <Group key={`bar-${name}`}>
                    <Text x={0} y={barY} dy={`1.45em`} fill="white">
                      {capitalCase(name)}
                    </Text>
                    <Bar
                      x={200}
                      y={barY}
                      width={barHeight}
                      height={barWidth}
                      fill={colorScale(name)}
                    />
                    <Text x={barHeight + 210} y={barY} dy={`1.45em`} fill="white">
                      {strandings}
                    </Text>
                  </Group>
                );
              })}
            </Group>
          </svg>)
        }}
      </ParentSize>
    </Box>
  )
}