import React from 'react';
import { ThemeUIProvider, Box, Heading, Paragraph, Link } from 'theme-ui'
import { colors } from '@theme-ui/preset-tailwind';

import theme from './app/theme'
import { useAppDispatch } from './app/hooks';
import HorizontalBarChart from './features/stranding-by-region/HorizontalBarChart';
import StackedBarChart from './features/stranding-by-region/StackedBarChart';
import { fetchDataAsync } from './features/stranding-data/strandingDataSlice';
import { fetchDataAsync as fetchTopoDataAsync }
  from './features/california-topo-data/californiaTopoDataSlice';

import Hero from './features/hero';
import CountiesMap from './features/stranding-by-region/CountiesMap';
import DetailsByCountyPanel from './features/details-by-county/DetailsByCountyPanel';
import Spotlight from './features/spotlight';
import { Lightfox } from './features/spotlight/icons';

function App() {
  const dispatch = useAppDispatch();
  dispatch(fetchDataAsync());
  dispatch(fetchTopoDataAsync());

  return (
    <ThemeUIProvider theme={theme}>
      <Box as="main" variant="layout.app">
        <Hero />
        <Box sx={{ mb: 4 }}>
          <Heading as="h2" variant="styles.h2" sx={{ mb: 3 }}>
            Geography
          </Heading>
          <Paragraph>
            Sea otters once lived all around the North Pacific Rim, from Japan to Mexico, including from Oregon to Baja California. After nearly 200 years of commercial hunting, their numbers drastically dropped, with a small group surviving along California's Big Sur coast. Since hunting was banned in 1911, these otters have spread along 200 miles of the California coast. Today, they live in about 13% of their original range, mainly from Monterey Bay to Point Conception in Santa Barbara County. Shark attacks, especially at the edges of their range, might be hindering their further spread into new areas.
          </Paragraph>
        </Box>
        <CountiesMap />
        <Box sx={{ my: 4 }}>
          <Heading as="h2" variant="styles.h2" sx={{ mb: 3 }}>
            As the Otter Swims...
          </Heading>
          <Paragraph>
            When a stranded sea otter is found, its location is marked using a special system called As-The-Otter-Swims (ATOS). ATOS assigns a number to points every 0.5 kilometers along a specific underwater contour line on the California coast. This system was created before GPS became common, but it’s still used today to keep track of both new and old data in a consistent way. This helps researchers compare where otters have been found over time.
          </Paragraph>
        </Box>
        <Box sx={{
          display: `flex`,
          justifyContent: `space-between`,
          flexDirection: [`column`, `column`, `column`, `row`],
        }}>
          <HorizontalBarChart />
          <Spotlight />
        </Box>
        <Box sx={{ my: 4 }}>
          <Heading as="h2" variant="styles.h2" sx={{ mb: 3 }}>
            Significant Decrease in Sea Otter Strandings in 2020 and 2022
          </Heading>
          <Paragraph sx={{ mb: 3 }}>
            2020 saw a 35% decrease in otter strandings from previous years. Strandings were lower almost every month, with March seeing the biggest drop of over 75% compared to March 2019. It is possible that the COVID-19 pandemic, in addition to wildfires and other factors, affected how stranded sea otters are reported, recovered, and examined.
            2022 surpassed 2020's record, with 266 confirmed stranded southern sea otters, a 15% decrease from 2021 and the lowest number since 2009.
          </Paragraph>
          <Paragraph>
            San Luis Obispo and Monterey were the counties with the highest numbers of otter strandings,{' '}which is consistent with their current geographic distribution.
          </Paragraph>
        </Box>
        <StackedBarChart
          width={1000}
          height={600}
        />
        <Box sx={{ mt: 5, mb: 4 }}>
          <Heading as="h2" variant="styles.h2" sx={{ mb: 3 }}>
            Stranding Metrics
          </Heading>
          <Paragraph sx={{ mb: 3 }}>
            Some details are tracked when a stranding is reported. Beyond time and location, these include: the otter condition, sex, age class, and cause of stranding.
          </Paragraph>
        </Box>
        <DetailsByCountyPanel />
      </Box>
      <Box sx={{ mt: 7, mb: 5, textAlign: `center`}}>
        <Box>
          <small>Made with ♡ by{' '}
            <Link href="https://lightfox.io" sx={{
              svg: {
                display: `inline-block`,
                position: `relative`,
                top: `1px`,
                ml: `3px`,
              }
            }}>
              <Lightfox width={19} height={12} stroke={colors.emerald[4]} />
              {' '}Lightfox
            </Link>.{' '}
            © 2024 Lightfox, LLC.
            </small>
          </Box>
      </Box>
    </ThemeUIProvider>
  );
}

export default App;
