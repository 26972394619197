import React from 'react';
import { Box, Heading, Grid } from 'theme-ui';
import { useParentSize } from '@visx/responsive';

import { background } from '../stranding-by-region/chartStyles';
import CountySelectField from './CountySelectField';
import DonutChart from './DonutChart';
import DetailsByCountyLegend from './DetailsByCountyLegend';
import StrandingFeatureSelectField from './StrandingFeatureSelectField';
import { chooseDetailYear } from './detailsByCountySlice';
import YearSelectField from '../../app/YearSelectField';
import DetailsByCountyIntro from './DetailsByCountyIntro';

const DetailsByCountyPanel = () => {
  const { parentRef, width } = useParentSize();
  const donutSize = width < 500 ? width : 436;
  return (
    <Box sx={{ backgroundColor: background, border: `1px solid white`, padding: 4}}
      ref={parentRef}>
      <Heading variant="styles.h3" as="h2" sx={{ mt: 0 }}>
        Stranding Details by County
      </Heading>
      <Grid sx={{ mt: 3, mb: 4 }} columns={[1, 2, 3]} gap={[3, 3, 4]}>
        <CountySelectField />
        <StrandingFeatureSelectField />
        <YearSelectField action={chooseDetailYear} />
      </Grid>
      <DetailsByCountyIntro />
      <Grid sx={{ mt: 3, mb: 3 }} columns={[1, 1, 1, 2]} gap={[4, 4, 4, 5]}>
        <DonutChart width={donutSize} height={donutSize} />
        <DetailsByCountyLegend />
      </Grid>
    </Box>
  );
};

export default DetailsByCountyPanel;