import { merge } from 'theme-ui'
import tailwind from '@theme-ui/preset-tailwind'

const baseFonts = {
  sans: '"Space Grotesk",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  heading: '"Jost",system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
}

const fonts = {
  ...baseFonts,
  body: baseFonts.sans,
  heading: baseFonts.heading,
}

export default merge(tailwind, {
  colors: {
    background: `transparent`,
    text: tailwind.colors.white,
    primary: tailwind.colors.green[4],
    primaryHover: tailwind.colors.blue[8],
  },
  fonts,
  layout: {
    app: {
      mt: 4,
      mb: 5,
      marginX: `auto`,
      width: [
        `calc(100% - 2rem)`,
        `calc(100% - 2rem)`,
        `calc(100% - 4rem)`,
        `960px`,
        `1000px`,
      ]
    },
    hero: {
      position: `relative`,
      minHeight: [`auto`, `auto`, `auto`, `75vh`],
      color: `white`,
      h1: {
        textAlign: [`center`, `right`],
        maxWidth: [`none`, `none`, `66%`, `none`],
        marginLeft: [0, 0, `30%`, 0],
      },
      img: {
        position: [`static`, `static`, `absolute`],
        left: [0, 0, `70px`, `-30px`],
        top: [0, 0, `10px`, `40px`],
        zIndex: -1,
        width: [`100%`, `400px`, `400px`, `500px`],
        maxWidth: `100%`,
        margin: `0 auto`,
        display: `block`,
      },
      '.hero-intro': {
        mt: 4,
        mb: 5,
      },
      h2: {
        my: 5,
        textAlign: `center`,
        span: {
          display: `block`,
          fontSize: tailwind.fontSizes["3xl"],
          mt: 2,
        }
      },
      p: {
        mb: 3,
      }

    }
  },
  styles: {
    root: {
      // background: `linear-gradient(0deg, #483bff, #5ce9ff)`,
      // background: `linear-gradient(-225deg, #80D9FF, #649EBE, #486FA2, #2C416A)`,
      // background: `linear-gradient(45deg, #006699, #6699CC, #99CCFF, #99E6FF)`,
      // background: `linear-gradient(0, #182e4d, #094089, #289dbc)`,
      // background: `linear-gradient(0deg, #0f1c3a, #0f1c3a, #0f1c3a, #133359, #165279, #177c9b, #16b2bf, #13e5d5)`,
      background: `linear-gradient(45deg, #000133, #06218c, #4d68cf, #43d1cb)`,
    },
    hero1: {
      fontWeight: 600,
      letterSpacing: `0.03em`,
      fontSize: [
        tailwind.fontSizes["5xl"],
        tailwind.fontSizes["7xl"],
        tailwind.fontSizes["8xl"],
        `10rem`
      ],
      mt: [4, 4, `11rem`],
      mb: [5, 5, `14rem`],
      textTransform: `uppercase`,
    },
    hero2: {
      fontSize: [tailwind.fontSizes["3xl"], tailwind.fontSizes["4xl"]],
      fontWeight: 500,
      my: 4,
    },
    h1: {
      fontSize: [tailwind.fontSizes["2xl"], tailwind.fontSizes["3xl"]],
      fontWeight: 600,
      mt: 2,
    },
    h2: {
      fontSize: [tailwind.fontSizes["xl"], tailwind.fontSizes["2xl"]],
      fontWeight: 500,
      mt: 2,
    },
    h3: {
      fontSize: [tailwind.fontSizes["lg"], tailwind.fontSizes["xl"]],
      fontWeight: 500,
      mt: 3,
    },
    h4: {
      fontSize: [tailwind.fontSizes.default, tailwind.fontSizes.lg],
      fontWeight: 400,
      textTransform: `uppercase`,
    },
    h5: {
      fontFamily: baseFonts.sans,
      fontSize: tailwind.fontSizes.sm,
    },
    h6: {
      fontFamily: baseFonts.sans,
      fontSize: tailwind.fontSizes.default,
      mb: 2,
    },
    a: {
      textDecoration: `none`,
    }
  }
})
