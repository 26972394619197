import React from 'react';
import { Select, Box, Label } from 'theme-ui';

import { useAppDispatch } from './hooks'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

type YearSelectFieldProps = {
  action: ActionCreatorWithPayload<any>;
}

const YearSelectField = ({ action }: YearSelectFieldProps) => {
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Label>Year</Label>
      <Select onChange={(e) => dispatch(action(e.target.value))}>
        <option value="ALL">All</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
      </Select>
    </Box>
  );
};

export default YearSelectField;
