import React, { LegacyRef } from 'react';
import { Box, Heading, Flex } from 'theme-ui';
import { Mercator, Graticule } from '@visx/geo';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { geoCentroid, geoMercator } from '@visx/vendor/d3-geo';
import { Zoom } from '@visx/zoom';
import { useSelector } from 'react-redux';

import { useAppSelector } from '../../app/hooks';
import { FeatureShape } from '../california-topo-data/californiaTopoDataTypes';
import { selectCounties, selectAllDataByYear } from
  '../stranding-data/strandingDataSelectors';
import { selectCaliforniaFeatures } from
  '../california-topo-data/californiaTopoDataSelectors';
import { getColorScale } from './chartStyles';
import { chooseMapYear } from './strandingByRegionSlice';
import { capitalCase } from '../../app/utils';
import YearSelectField from '../../app/YearSelectField';

type CountiesMapProps = {
  translateX: number;
  translateY: number;
  width: number;
};

type Offset = {
  [key: string]: number[];
};
const needsCoordOffset: Offset = {
  'San Mateo': [50, -5],
  'Santa Cruz': [60, -20],
  'Monterey': [10, 0],
};

const center: [number,number] = [-118.81, 34.33]; // CA central coast
const scale = 7500;

const CountiesMap = React.memo(
  function CountiesMap({ translateX, translateY, width }: CountiesMapProps) {
    const otterCounties = useSelector(selectCounties) as string[];
    const california = useAppSelector(selectCaliforniaFeatures) as {
      type: 'FeatureCollection';
      features: FeatureShape[];
    };

    if (!california) {
      return null;
    }

    return (
      <Mercator
        data={california.features}
        center={center}
        scale={scale}
        translate={[translateX, translateY]}
      >
        {(mercator) => {
        return <g>
          <Graticule graticule={(g) => mercator.path(g) || ''} stroke="rgba(33,33,33,0.05)" />
          {mercator.features.map(({ feature, path, projection }, i) => {
            const originalCoords: [number, number] | null = projection(geoCentroid(feature));
            let coords = originalCoords;
            const name = feature.properties.NAME;
            const isOtterCounty = otterCounties.find(
              (c) => c === name.toUpperCase()
            )

            if (isOtterCounty && needsCoordOffset[name] !== undefined && coords !== null) {
              coords = [
                coords[0] - needsCoordOffset[name][0],
                coords[1] - needsCoordOffset[name][1]
              ]
            }

            return (
              <React.Fragment key={`map-feature-${i}`}>
                <path
                  key={`map-feature-${i}`}
                  d={path || ''}
                  fill={`rgba(255,255,255,0.2)`}
                  stroke={`#ccc`}
                  strokeWidth={1}
                />
                { isOtterCounty ? (
                    <text
                      transform={`translate(${coords})`}
                      fontSize={Math.max(width / 80, 9)}
                      style={{ fill: `white`}}
                      textAnchor="middle"
                    >
                      {name}
                    </text>
                  ) : ''
                }
              </React.Fragment>
            )
          })}
        </g>
        }}
      </Mercator>
    )
  }
);

type StrandingLocationsProps = {
  translateX: number;
  translateY: number;
};

const colorScale = getColorScale(
  [
    'UNKNOWN',
    'UNKNOWN, WITH TRAUMA',
    'UNKNOWN, NO TRAUMA',
    'MISC',
    'ANTHROPOGENIC',
    'DEPENDENT ANIMAL',
    'SHARK BITE',
  ]
);

const StrandingLocations = ({ translateX, translateY }: StrandingLocationsProps) => {
  const allStrandings = useSelector(selectAllDataByYear);
  const projection = geoMercator()
    .center(center)
    .translate([translateX, translateY])
    .scale(scale);

  return (
    <g>
      {allStrandings?.map((stranding, i) => (
        stranding['GPS LOCATION'] !== null ? (
          <circle
            key={i}
            r={5}
            x={stranding['GPS LOCATION'][0]}
            y={stranding['GPS LOCATION'][1]}
            // fill="#c5ff3d"
            fill={colorScale(stranding['COS CATEGORY'])}
            stroke="#333"
            strokeWidth={0}
            opacity={0.7}
            transform={`translate(${projection(stranding['GPS LOCATION'])})`}
          />
        ) : null
      ))}
    </g>
  )
}

export type ZoomableMapProps = {
  width?: number;
  height?: number;
  events?: boolean;
};

const ZoomableMap = ({ width = 1000, height = 700, events = false }: ZoomableMapProps) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const scale = 0.8;

  return width < 10 ? null : (
    <Zoom
      width={width}
      height={height}
      scaleXMin={0.8}
      scaleXMax={5}
      scaleYMin={0.8}
      scaleYMax={5}
      initialTransformMatrix={{
        scaleX: scale,
        scaleY: scale,
        translateX: centerX,
        translateY: centerY,
        skewX: 0,
        skewY: 0
      }}
    >
      {(zoom) => {
        return (
          <svg
            width={width}
            height={height}
            style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab', touchAction: 'none' }}
            ref={zoom.containerRef as LegacyRef<SVGSVGElement>}
          >
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              fill={`transparent`}
              stroke={`white`}
              strokeWidth={1}
            />
            {/** apply the tranform to the g so we don't have to re-render the entire map */}
            <g
              transform={`translate(${zoom.transformMatrix.translateX}, ${zoom.transformMatrix.translateY})scale(${zoom.transformMatrix.scaleX}, ${zoom.transformMatrix.scaleY})`}
            >
              <rect width={10} height={10} fill="yellow" />
              <g>
                <CountiesMap translateX={centerX} translateY={centerY} width={width} />
                <StrandingLocations translateX={centerX} translateY={centerY} />
              </g>
            </g>
            {/** intercept all mouse events */}
            <rect
              x={0}
              y={0}
              width={width}
              height={height}
              rx={14}
              fill="transparent"
              onTouchStart={zoom.dragStart}
              onTouchMove={zoom.dragMove}
              onTouchEnd={zoom.dragEnd}
              onMouseDown={zoom.dragStart}
              onMouseMove={zoom.dragMove}
              onMouseUp={zoom.dragEnd}
              onMouseLeave={() => {
                if (zoom.isDragging) zoom.dragEnd();
              }}
            />
          </svg>
        );
      }}
    </Zoom>
  );
};

const OtterMap = () => {
  const legendGlyphSize = 15;

  return (
    <Box sx={{
      position: `relative`
    }}>
      <Heading variant="styles.h3" as="h2" sx={{
        position:`absolute`, left: `20px`, top: `20px`, mt: 0
      }}>
        Geographic Distribution of Strandings
      </Heading>
      <ParentSize>
        {({ width }) => (
          <ZoomableMap width={width} />
        )}
      </ParentSize>
      <Box sx={{
        position:`absolute`, left: `20px`, top: `60px`, mt: 0, minWidth: `85px`
      }}>
        <YearSelectField action={chooseMapYear} />
      </Box>
      <Box sx={{
        position: `absolute`,
        bottom: `28px`,
        left: `20px`,
        width: `160px`,
        lineHeight: `0.9em`,
        fontSize: `10px`,
        padding: `10px 10px`,
        border: `1px solid white`,
      }}>
        <Heading variant="styles.h5" as="h4" sx={{ mb: 2 }}>
          Cause of Stranding
        </Heading>
        <LegendOrdinal
          scale={colorScale}
          labelFormat={(label) => `${capitalCase(label)}`}>
          {(labels) => (
            <Flex sx={{ flexDirection: 'column' }}>
              {labels.map((label, i) => (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  margin="0 5px 5px"
                >
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect
                      fill={label.value}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </Flex>
          )}
        </LegendOrdinal>
      </Box>
    </Box>
  )
};

export default OtterMap;
