import React from 'react';
import { Box } from 'theme-ui'
import { keyframes } from '@emotion/react'
import { random } from 'lodash-es';

const crawl = keyframes`
  0% {
    transform: translateX(130%) rotate(20deg);
  }
  100% {
    transform: translateX(-130%) rotate(20deg);
  }
`;

const blinkRay = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;

// Loosely based on https://codepen.io/tdoughty/pen/ZZqgQq
const AnimatedLight = () => (
  <Box className="animated-surface-light" sx={{
    position: `fixed`,
    top: 0,
    left: 0,
    width: `100%`,
    zIndex: -1,
    animation: `${crawl} 16s linear infinite forwards`,
    transformStyle: `preserve-3d`,
    overflow: `visible`,
  }}>
    <Box className="ray" sx={{
      width: `30%`,
      height: `300vh`,
      background: `radial-gradient(closest-side, #B4D1E7, transparent)`,
      animation: `${blinkRay} ${random(3, 6)}s linear infinite alternate`,
      opacity: 1,
      position: `absolute`,
      top: 0,
      left: `-60%`,
      transformOrigin: `center`,
      transform: `translateY(-65%)`
    }}/>
    <Box className="ray" sx={{
      width: `30%`,
      height: `300vh`,
      background: `radial-gradient(closest-side, #13e5d5, #16b2bf, transparent)`,
      animation: `${blinkRay} ${random(3, 6)}s linear infinite alternate`,
      opacity: 1,
      position: `absolute`,
      top: 0,
      left: `-20%`,
      transformOrigin: `center`,
      transform: `translateY(-65%)`
    }}/>
    <Box className="ray" sx={{
      width: `50%`,
      height: `250vh`,
      background: `radial-gradient(closest-side, #97fcdc, #43e1b0, transparent)`,
      animation: `${blinkRay} ${random(2, 4)}s linear infinite alternate`,
      opacity: 1,
      position: `absolute`,
      top: 0,
      left: 0,
      transformOrigin: `center`,
      transform: `translateY(-65%)`
    }}/>
    <Box className="ray" sx={{
      width: `30%`,
      height: `300vh`,
      background: `radial-gradient(closest-side, #99CCFF, transparent)`,
      animation: `${blinkRay} ${random(3, 6)}s linear infinite alternate`,
      opacity: 1,
      position: `absolute`,
      top: 0,
      left: `30%`,
      transformOrigin: `center`,
      transform: `translateY(-65%)`
    }}/>
     <Box className="ray" sx={{
      width: `40%`,
      height: `270vh`,
      background: `radial-gradient(closest-side, #43e1b0, transparent)`,
      animation: `${blinkRay} ${random(2, 4)}s linear infinite alternate`,
      opacity: 1,
      position: `absolute`,
      top: 0,
      left: `60%`,
      transformOrigin: `center`,
      transform: `translateY(-50%)`
    }}/>
    <Box className="ray" sx={{
      width: `30%`,
      height: `300vh`,
      background: `radial-gradient(closest-side, #99CCFF, transparent)`,
      animation: `${blinkRay} ${random(3, 6)}s linear infinite alternate`,
      opacity: 1,
      position: `absolute`,
      top: 0,
      left: `90%`,
      transformOrigin: `center`,
      transform: `translateY(-65%)`
    }}/>
  </Box>
);

export default AnimatedLight;
