import { createSelector } from "@reduxjs/toolkit"
import * as topojson from 'topojson-client';
import { GeoJsonProperties } from "geojson";
import { Topology, Objects } from "topojson-specification";

import { selectData } from "./californiaTopoDataSlice"

export const selectCaliforniaFeatures = createSelector(
  [selectData],
  (data) => {
    const topoData = data as Topology<Objects<GeoJsonProperties>>
    if (!topoData || !topoData.objects) {
      return null;
    }
    return topojson.feature(topoData , topoData.objects.counties)
  }
)