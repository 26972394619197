import React from 'react';
import { Heading, Box } from 'theme-ui';
import { BarStack } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear } from '@visx/scale';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { LegendOrdinal } from '@visx/legend';
import { localPoint } from '@visx/event';
import { ParentSize } from "@visx/responsive";
import { useSelector } from 'react-redux';

import { CountyNames, StrandingsByCounty } from '../stranding-data/strandingDataTypes'
import {
  selectCounties,
  selectAllStrandingsByCounty,
  selectAllStrandingsByCountyFilteredByYear
} from '../stranding-data/strandingDataSelectors';
import YearSelectField from '../../app/YearSelectField';
import { axisColor, getColorScale } from './chartStyles';
import { capitalCase } from '../../app/utils';
import { chooseStrandingsOverTimeYear } from './strandingByRegionSlice';

type StrandingTooltipData = {
  bar: SeriesPoint<StrandingsByCounty>;
  key: CountyNames;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type BarStackProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const defaultMargin = { top: 80, right: 30, bottom: 0, left: 50 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

const parseDate = timeParse('%Y-%m');
const format = timeFormat('%b %Y');
const formatDate = (date: string) => format(parseDate(date) as Date);

// accessors
const getStrandingDate = (d: StrandingsByCounty) => d.DATE

let tooltipTimeout: number;

export default function StackedBarChart({
  width,
  height = 640,
  events = false,
  margin = defaultMargin,
}: BarStackProps) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<StrandingTooltipData>();
  const allStrandings = useSelector(selectAllStrandingsByCounty);
  const strands = useSelector(selectAllStrandingsByCountyFilteredByYear)
  const counties = useSelector(selectCounties) as CountyNames[]

  // calculate totals based on all strandings instead of per year to make sure
  // the y axis scale remains the same
  const strandingTotals = allStrandings.reduce(
    (allTotals: number[], currentDate: {'DATE': string, [key: string]: any} ) => {
      const totalStrandings = counties.reduce((dailyTotal, county) => {
        if (currentDate[county]) {
          dailyTotal += Number(currentDate[county]);
        }
        return dailyTotal;
      }, 0);
      allTotals.push(totalStrandings);
      return allTotals;
    },
    [] as number[]
  );

  const strandDateScale = scaleBand<string>({
    domain: strands.map((strand) => strand['DATE']),
    padding: 0.2,
  })
  const strandingTotalsScale = scaleLinear<number>({
    domain: [0, Math.max(...strandingTotals)],
    nice: true,
  });
  const strandingColorScale = getColorScale(counties);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  if (width < 10) return null;

  return width < 10 ? null : (
    <div style={{ position: 'relative', margin: `0 0 2em`, border: `1px solid white` }}>
      <Heading as="h2" variant="styles.h3"
        sx={{
          position: [`static`, `absolute`],
          top: `10px`,
          left: defaultMargin.left - 20,
          mt: [`30px`, 3],
          mx: [`30px`, 0]
        }}
      >
        Strandings Over Time By County
      </Heading>
      <Box sx={{
        position: [`static`, `absolute`],
        right: `30px`,
        top: `20px`,
        mt: [2, 0],
        mx: [`30px`, 0],
        minWidth: `130px`,
        '> div': {
          display: [`block`, `flex`,],
          alignItems: `center`,
          '> div': {
            minWidth: `80px`
          }
        }
      }}>
        <YearSelectField action={chooseStrandingsOverTimeYear} />
      </Box>
      <ParentSize>
        {({ width }) => {
          const isPhone = width < 639;
          const top = isPhone ? 50 : margin.top;

          // bounds
          const xMax = width - margin.left - margin.right;
          const yMax = height - margin.top - 60;

          strandDateScale.rangeRound([0, xMax]);
          strandingTotalsScale.range([yMax, 0]);

          return <svg ref={containerRef} width={width} height={height}>
            {/* <rect x={0} y={0}
              width={width} height={height}
              fill={background} stroke="white" strokeWidth={1}
            /> */}
            <Grid
              top={top}
              left={margin.left}
              xScale={strandDateScale}
              yScale={strandingTotalsScale}
              width={xMax}
              height={yMax}
              stroke="white"
              strokeOpacity={0.3}
              xOffset={strandDateScale.bandwidth() / 2}
            />
            <Group top={top}>
              <BarStack<StrandingsByCounty, CountyNames>
                left={margin.left}
                data={strands}
                keys={counties}
                x={getStrandingDate}
                xScale={strandDateScale}
                yScale={strandingTotalsScale}
                color={strandingColorScale}
              >
                {(barStacks) =>
                  barStacks.map((barStack) =>
                    barStack.bars.map((bar) => (
                      <rect
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={bar.x + margin.left}
                        y={bar.y}
                        height={bar.height}
                        width={bar.width}
                        fill={bar.color}
                        onClick={() => {
                          if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                        }}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          // TooltipInPortal expects coordinates to be relative to containerRef
                          // localPoint returns coordinates relative to the nearest SVG, which
                          // is what containerRef is set to in this example.
                          const eventSvgCoords = localPoint(event);
                          const left = bar.x + bar.width / 2;
                          showTooltip({
                            tooltipData: bar,
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    )),
                  )
                }
              </BarStack>
            </Group>
            <AxisBottom
              top={yMax + top}
              left={margin.left}
              scale={strandDateScale}
              numTicks={isPhone ? 6: 12}
              tickFormat={formatDate}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={{
                fill: axisColor,
                fontSize: 11,
                textAnchor: 'middle',
                width: 20,
                height: 40,
                dy: '1em'
              }}
            />
            <AxisLeft
              top={top}
              left={margin.left}
              scale={strandingTotalsScale}
              stroke={axisColor}
              tickStroke={axisColor}
              tickLabelProps={{
                fill: axisColor,
              }}
            />
        </svg>
      }}
      </ParentSize>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '14px',
          mt: [`-16px`],
          mb: `30px`,
          mx: `30px`,
          '.visx-legend': {
            flexWrap: `wrap`,
          }
        }}
      >
        <LegendOrdinal
          scale={strandingColorScale}
          direction="row"
          labelMargin="0 15px 0 0"
          labelFormat={(label) => (capitalCase(label))}
        />
      </Box>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div style={{ color: strandingColorScale(tooltipData.key) }}>
            <strong>{capitalCase(tooltipData.key)}</strong>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]}</div>
          <div>
            <small>{formatDate(getStrandingDate(tooltipData.bar.data))}</small>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
}
