import { scaleOrdinal } from '@visx/scale';
import { interpolateCool } from 'd3-scale-chromatic';
import { range } from 'lodash-es';

export const background = 'transparent';
export const axisColor = 'white';

export const getColorScale = (domain: string[]) => {
  return scaleOrdinal({
    domain: domain,
    range: range(0, 1, 1/domain.length).map((num) => interpolateCool(num))
  })
}
