/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { useSelector } from 'react-redux';
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GradientPurpleTeal } from '@visx/gradient';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { animated, useTransition, to } from '@react-spring/web';

import { selectDonutData } from './detailsByCountySelectors';


interface DonutData {
  label: string;
  value: number;
}

type DonutTooltipData = {
  data: DonutData;
  value: number;
  index: number;
  startAngle: number;
  endAngle: number;
  padAngle: number;
};

// accessor functions
const getValue = (d: DonutData) => d.value;

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

export type PieProps = {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  animate?: boolean;
};

let tooltipTimeout: number;
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

export default function DonutChart({
  width,
  height,
  margin = defaultMargin,
  animate = true,
}: PieProps) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<DonutTooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const donutData = useSelector(selectDonutData);
  const labelNames = donutData.sort((a, b) => b.value - a.value).map((d) => d.label);

  // color scales
  const getColor = scaleOrdinal({
    domain: labelNames,
    range: labelNames.map((n, i) => `rgba(17,24,39, ${0.8 - (i * 0.1)})`),
  });


  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 80;

  return (
    <svg width={width} height={height} ref={containerRef} style={{ margin: `0 auto`}}>
      <GradientPurpleTeal id="visx-pie-gradient" />
      <rect rx={14} width={width} height={height} fill="url('#visx-pie-gradient')" />
      <Group top={centerY + margin.top} left={centerX + margin.left}>
        <Pie
          data={donutData}
          pieValue={getValue}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          cornerRadius={3}
          padAngle={0.005}
        >
          {(pie) => (
            <AnimatedPie<DonutData>
              {...pie}
              animate={animate}
              getKey={(arc) => arc.data.label}
              getValue={(arc) => arc.data.value}
              getColor={(arc) => getColor(arc.data.label)}
              onMouseLeaveDatum={() => {
                tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 300);
              }}
              onMouseMoveDatum={(arc, event) => {
                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                const eventSvgCoords = localPoint(event);
                showTooltip({
                  tooltipData: arc,
                  tooltipTop: eventSvgCoords?.y,
                  tooltipLeft: eventSvgCoords?.x,
                });
              }}
            />
          )}
        </Pie>
      </Group>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div>
            <strong>{tooltipData.data.label}</strong>
          </div>
          <div>{tooltipData.data.value}</div>
        </TooltipInPortal>
      )}
    </svg>
  );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getValue: (d: PieArcDatum<Datum>) => number;
  getColor: (d: PieArcDatum<Datum>) => string;
  onMouseLeaveDatum: () => void;
  onMouseMoveDatum: (d: PieArcDatum<Datum>, e: React.MouseEvent) => void;
  delay?: number;
};

function AnimatedPie<Datum>({
  animate,
  arcs,
  path,
  getKey,
  getValue,
  getColor,
  onMouseLeaveDatum,
  onMouseMoveDatum,
}: AnimatedPieProps<Datum>) {
  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          // compute path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={getColor(arc)}
          onMouseLeave={() => onMouseLeaveDatum()}
          onMouseMove={(e) => {
            !hasSpaceForLabel && onMouseMoveDatum(arc, e);
          }}
        />
        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill="white"
              x={centroidX}
              y={centroidY}
              dy=".33em"
              fontSize={10}
              textAnchor="middle"
              pointerEvents="none"
            >
              <tspan x={centroidX} dy="-1.5em">{getKey(arc)}</tspan>
              <tspan x={centroidX} dy="1.5em" fontSize={9}>{getValue(arc)}</tspan>
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}
