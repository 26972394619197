import React from 'react';
import { Box, Heading, Paragraph, Image, Link } from 'theme-ui'

import AnimatedBubbles from './AnimatedBubbles';
import AnimatedLight from './AnimatedLight';

const Hero = () => {
  return (
    <Box className="hero" variant="layout.hero">
      <AnimatedLight />
      <AnimatedBubbles />
      <Image src="./images/otter-hero.png" />
      <Heading variant="styles.hero1" as="h1">
        You Otter Know
      </Heading>
      <Box className="hero-intro">
        <Heading variant="styles.hero2" as="h2">
          Visualizing Southern Sea Otter Stranding Data<span>2019 - 2023</span>
        </Heading>
        <Box sx={{ columnCount: [1, 1, 1, 2], columnGap: 4 }}>
          <Paragraph>
            Since 1968, the California Department of Fish and Wildlife (CDFW) and various partners, including the Monterey Bay Aquarium (MBA), The Marine Mammal Center (TMMC), and the U.S. Geological Survey (USGS), have been tracking and helping stranded southern sea otters. This includes otters that are sick, injured, or dead, found on beaches or floating in the water. The MBA and TMMC focus on rehabilitating these otters, while CDFW and TMMC handle necropsies (animal autopsies). Reports of stranded otters come from the public and various officials, and each confirmed case is documented and examined.
          </Paragraph>
          <Paragraph>
            This data is taken from the <Link href="https://data.cnra.ca.gov/dataset/southern-sea-otter-stranding-database" target="blank">public dataset and reports</Link> prepared by Colleen Young and Mike Harris of the California Department of Fish and Wildlife, Office of Spill Prevention and Response, and Marine Wildlife Veterinary Care and Research Center Santa Cruz, CA.
          </Paragraph>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
