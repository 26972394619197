import { csv } from "d3-fetch"
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';

export const fetchStrandingData = async () => {
  const data2019 = await csv("/data/stranding_data_2019.csv")
  const data2020 = await csv("/data/stranding_data_2020.csv")
  const data2021 = await csv("/data/stranding_data_2021.csv")
  const data2022 = await csv("/data/stranding_data_2022.csv")
  const data2023 = await csv("/data/stranding_data_2023.csv")
  const atos = await csv("/data/atos.csv")

  const atosDict = atos.reduce((accumulator: object, value) => (
    {...accumulator, [value.ATOS]: [parseFloat(value.LONG), parseFloat(value.LAT)]}
  ), {})

  const cleaned = data2019
    .concat(data2020)
    .concat(data2021)
    .concat(data2022)
    .concat(data2023)
    .map(datum => {
      const parseDate = timeParse('%e-%b-%y');
      const format = timeFormat('%Y-%m-%d');
      const formatted = format(parseDate(datum['DATE']) as Date);
      return {
        ...datum,
        'COUNTY': datum['COUNTY'].toUpperCase(),
        'DATE': formatted,
        'GPS LOCATION': atosDict[datum.ATOS] || null,
      }
    })

  return {
    data: cleaned,
    columns: data2019.columns
  }
}
