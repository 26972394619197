import React from 'react';
import { Box, Flex, Paragraph } from 'theme-ui';

import { Boat, Shark, Otter } from './icons';

type SpotlightItemProps = {
  style?: object,
  children: React.ReactNode
}
const SpotlightItem = ({ children, style = {} }: SpotlightItemProps) => (
  <Flex className="spotlight-item" sx={{
    '> :nth-child(2)': {
      ml: [0, 0, 0, 4]
    },
    '.icon': {
      maxWidth: [`25%`, `20%`, `15%`, `none`],
      mr: [0, 4, 5, 0],
      my: [4, 0, 0, 0]
    },
    alignItems: `center`,
    mb: 4,
    ...style
  }}>
    {children}
  </Flex>
)

const Spotlight = () => (
  <Box sx={{
    my: 5,
    marginLeft: [0, 0, 0, 5],
    '.spotlight-item': {
      flexDirection: [`column`, `row`, `row`, `row`],
      '&:nth-of-type(even)': {
        flexDirection: [`column-reverse`, `row-reverse`, `row-reverse`, `row`]
      }
    }
  }}>
    <SpotlightItem>
      <Shark width={180} />
      <Paragraph>
        Shark bites are the primary cause of otter strandings.
      </Paragraph>
    </SpotlightItem>
    <SpotlightItem style={{
      justifyContent: [`flex-start`, `flex-end`, `flex-end`, `flex-start`]
    }}>
      <Paragraph sx={{ width: [`100%`, `70%`]}}>
        With a total of 265 strandings, 2022 had the fewest amount of strandings since 2009.
      </Paragraph>
      <Box sx={{
        fontSize: `2.75rem`,
        fontWeight: `bold`,
        width: [`auto`, `20%`, `20%`, `auto`]}}
        className="icon"
      >
        2022
      </Box>
    </SpotlightItem>
    <SpotlightItem>
      <Otter width={180} />
      <Paragraph>
        Most otters stranded are male - this has been the case in 43 out of the past 56 years.
      </Paragraph>
    </SpotlightItem>
    <SpotlightItem style={{ mb: 0 }}>
      <Paragraph>
        Boat strikes, oil spills, and chemical run offs into the ocean are some ways humans adversely impact the well being of the otter population.
      </Paragraph>
      <Boat width={420} />
    </SpotlightItem>
  </Box>
);

export default Spotlight;
