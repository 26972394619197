import React from 'react';
import { Box } from 'theme-ui'
import { keyframes } from '@emotion/react'
import { random } from 'lodash-es';

const bubbleSize = `1rem`;
const bubbleDistance = `100vh`;
const bubbleMinSpeed = 5000;  // ms
const bubbleMaxSpeed = 15000; // ms
const bubbleMaxDelay = 12000;

const blow = keyframes`
  0% {
    opacity: 0;
    transform:translate(0, 0);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform:translate(0, -${bubbleDistance}) scale(.2);
  }
`;

// Modified from https://codepen.io/404ryannotfound/pen/OJVzQJP
const AnimatedBubbles = () => (
  <Box className="bubbles" sx={{
    position: `fixed`,
    bottom: 0,
    left: 0,
    width: `100%`,
    zIndex: -1,
  }}>
    {[...Array(18)].map((x, i) => (
      <React.Fragment key={`bubble-${i}`}>
        <Box className="bubble" sx={{
          opacity: 0,
          position: `absolute`,
          bottom: `-${bubbleSize}`,
          width: bubbleSize,
          height: bubbleSize,
          backgroundColor: `rgba(255,255,255,0.5)`,
          borderRadius: `50%`,
          left: `${random(1, 100)}%`,
          animation: `${blow} ${random(bubbleMinSpeed, bubbleMaxSpeed)}ms infinite`,
          animationDelay: `${random(1, bubbleMaxDelay)}ms`,
        }} />
        <Box className="bubble-outline" sx={{
          opacity: 0,
          position: `absolute`,
          bottom: `-${bubbleSize}`,
          width: bubbleSize,
          height: bubbleSize,
          border: `2px solid rgba(255,255,255,0.5)`,
          borderRadius: `50%`,
          left: `${random(1, 100)}%`,
          animation: `${blow} ${random(bubbleMinSpeed, bubbleMaxSpeed)}ms infinite`,
          animationDelay: `${random(1, bubbleMaxDelay)}ms`,
        }} />
      </React.Fragment>
    ))}
  </Box>
);

export default AnimatedBubbles;
