import React from 'react';
import { Select, Box, Label } from 'theme-ui';

import { useAppDispatch } from '../../app/hooks'
import { chooseStrandingFeature } from './detailsByCountySlice';

const StrandingFeatureSelectField = () => {
  const strandingFeatures = [
    { label: 'Condition', value: 'CONDITION' },
    { label: 'Age Class', value: 'AGE' },
    { label: 'Sex', value: 'SEX' },
    { label: 'Cause of Stranding', value: 'CAUSE' },
  ];
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Label>Stranding Feature</Label>
      <Select onChange={(e) => dispatch(chooseStrandingFeature(e.target.value))}>
        {strandingFeatures.map((feature, i) => (
          <option key={`${feature.value}-${i}`} value={feature.value}>
            {feature.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default StrandingFeatureSelectField;
